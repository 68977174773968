<template>
  <div class="vx-row">
    <div class="vx-col sm:w-full md:w-full lg:w-1/2 mb-base">
      <vx-card title="1. Order Information">
        <!-- FORM END -->
        <div class="vx-row mb-2">
          <div class="vx-col sm:w-full md:w-full">
            <browse-company
              class="w-full"
              name="company_id"
              label="Company"
              v-model="company_id"
            ></browse-company>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col sm:w-full md:w-1/2">
            <small class="ml-1">Packing List Date</small>
            <flat-pickr
              :config="flatPickrConfig"
              class="w-full"
              v-model="date_manifest"
            ></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <browse-route
              class="w-full"
              v-validate="'required'"
              name="route_id"
              label="Route"
              v-model="route_id"
            ></browse-route>
            <span class="text-danger text-sm" v-show="errors.has('route_id')">{{
              errors.first("route_id")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <browse-select-vehicle-type
              class="w-full"
              :value="vehicle_type_id"
              v-model="vehicle_type_id"
            ></browse-select-vehicle-type>
            <!-- <browse-vehicle-type class="w-full" v-validate="'required'" name="vehicle_type_id" label="Vehicle Type" v-model="vehicle_type_id"></browse-vehicle-type>
            <span class="text-danger text-sm" v-show="errors.has('vehicle_type_id')">{{errors.first('vehicle_type_id')}}</span> -->
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <small class="ml-1">ETD</small>
            <flat-pickr
              :config="flatPickrConfigTime"
              class="w-full"
              v-model="etd"
            ></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <small class="ml-1">ETA</small>
            <flat-pickr
              :config="flatPickrConfigTime"
              class="w-full"
              v-model="eta"
            ></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <small>Full / Multiple Order Item</small>
            <div class="mt-2">
              <input
                class="mr-2"
                type="radio"
                v-model="type"
                value="ftl"
                vs-value="ftl"
              />
              <span class="mr-6">FTL</span>

              <input
                class="mr-2"
                type="radio"
                v-model="type"
                value="ltl"
                vs-value="ltl"
              />
              <span class="mr-2">LTL</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              label="No Reff"
              v-model="no_reff"
            ></vs-input>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              label="Description"
              v-model="description"
            ></vs-input>
          </div>
        </div>
        <!-- FORM END -->
      </vx-card>
    </div>
    <div class="vx-col sm:w-full md:w-full lg:w-1/2 mb-base">
      <vx-card title="2. Shipment">
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <small>New or choose from existing shipment</small>
            <div class="mt-2">
              <input
                class="mr-2"
                type="radio"
                v-model="shipment.type"
                value="new"
                vs-value="new"
              />
              <span class="mr-6">New</span>

              <input
                class="mr-2"
                type="radio"
                v-model="shipment.type"
                value="existing"
                vs-value="existing"
              />
              <span class="mr-2">Existing</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-2" v-if="shipment.type == 'existing'">
          <div class="vx-col w-full">
            <browse-delivery-order
              label="Shipment"
              v-model="shipment.delivery_order_id"
              :value="shipment.delivery_order_id"
            />
          </div>
        </div>
        <div class="vx-row mb-2">
          <span class="ml-4" v-if="shipment.type == 'new'">
            <vs-divider></vs-divider>
            <div class="vx-col w-full">
              <small>Ship by</small>
              <div class="mt-2">
                <input
                  class="mr-2"
                  type="radio"
                  v-model="shipment.shipping_by"
                  value="internal"
                  vs-value="internal"
                />
                <span class="mr-6">Own Fleet</span>

                <input
                  class="mr-2"
                  type="radio"
                  v-model="shipment.shipping_by"
                  value="vendor"
                  vs-value="vendor"
                />
                <span class="mr-2">Vendor Fleet</span>
              </div>
            </div>

            <span
              v-if="shipment.shipping_by == 'internal'"
              class="ml-3 mt-2 w-full"
            >
              <div class="vx-col w-full">
                <div class="vx-col w-full">
                  <browse-vehicle
                    class="w-full"
                    name="vehicle_id"
                    label="Fleet"
                    v-model="shipment.vehicle_id"
                  ></browse-vehicle>
                </div>
              </div>
              <div class="vx-col w-full mt-2">
                <div class="vx-col w-full">
                  <browse-driver
                    class="w-full"
                    :isdriver="true"
                    label="Driver"
                    name="driver_id"
                    v-model="shipment.driver_id"
                  ></browse-driver>
                </div>
              </div>
            </span>
            <span
              v-else-if="shipment.shipping_by == 'vendor'"
              class="ml-3 mt-2 w-full"
            >
              <div class="vx-col w-full">
                <div class="vx-col w-full">
                  <browse-vendor
                    class="w-full"
                    :isvendor="true"
                    name="vendor_id"
                    label="Vendor"
                    v-model="shipment.vendor_id"
                  ></browse-vendor>
                </div>
              </div>
              <div class="vx-col w-full mt-2">
                <div class="vx-col w-full">
                  <vs-input
                    class="w-full"
                    label="Fleet No"
                    v-model="shipment.no_fleet"
                  ></vs-input>
                </div>
              </div>
              <div class="vx-col w-full mt-2">
                <div class="vx-col w-full">
                  <vs-input
                    class="w-full"
                    label="Driver"
                    v-model="shipment.driver_name"
                  ></vs-input>
                </div>
              </div>
            </span>
          </span>
        </div>
      </vx-card>
    </div>
    <div v-if="1 == 2" class="vx-col sm:w-full md:w-1/2 mb-base">
      <vx-card title="2. Goods">
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <browse-job-order
              :params="{
                is_route: true,
                route_id: route_id,
                is_mode: true,
                is_vehicle_type: true,
                vehicle_type_id: vehicle_type_id,
              }"
              class="w-full"
              label="Job Order"
              v-model="fd_job_order_id"
            ></browse-job-order>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full mt-2 mb-2">
            <vs-checkbox v-model="fd_is_all"
              >Transport all commodities from Job Order</vs-checkbox
            >
          </div>
        </div>
        <div v-show="!fd_is_all">
          <div class="vx-row mb-2">
            <div class="vx-col w-full">
              <browse-job-order-d
                @on-change="commodityChange"
                :params="{ job_order_id: fd_job_order_id }"
                class="w-full"
                label="Job Order's Commodities"
                v-model="fd_job_order_detail_id"
              ></browse-job-order-d>
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col sm:w-full md:w-1/2">
              <div class="vx-col w-full">
                <browse-contact
                  class="w-full"
                  :iscustomer="true"
                  v-validate="'required'"
                  name="customer_id"
                  label="Customer"
                  v-model="customer_id"
                ></browse-contact>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('customer_id')"
                  >{{ errors.first("customer_id") }}</span
                >
              </div>
            </div>
            <div class="vx-col sm:w-full md:w-1/2">
              <vs-input
                disabled
                type="number"
                class="w-full"
                label="Already on Packing List"
                v-model="fd_transport"
              ></vs-input>
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col sm:w-full md:w-1/2">
              <vs-input
                type="number"
                class="w-full"
                @focus="$event.target.select()"
                label="Transporting"
                v-model="fd_qty"
              ></vs-input>
            </div>
          </div>
        </div>
        <vs-divider></vs-divider>
        <vs-button
          size="small"
          @click="append"
          icon-pack="feather"
          icon="icon-arrow-down"
          >Append to Table</vs-button
        >
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="3. Commodities">
        <template slot="actions">
          <vs-button
            class="ml-2 float-right"
            color="primary"
            @click="openItems()"
            @on-change="selectItem"
            >Add Items</vs-button
          >
        </template>
        <browse-job-order-item
          class="hidden"
          :is_open="is_open"
          @on-change="selectItem"
        />
        <vs-table :data="details" class="mb-3">
          <template slot="thead">
            <vs-th>Commodity</vs-th>
            <vs-th>Unit</vs-th>
            <vs-th>Weight (kg)</vs-th>
            <vs-th>Volume (m<sup>3</sup>)</vs-th>
            <vs-th>Qty Origin</vs-th>
            <vs-th>Transported</vs-th>
            <vs-th>Transporting</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="i" v-for="(tr, i) in data">
              <vs-td>{{ tr.commodity }}</vs-td>
              <vs-td label="Unit">{{ tr.unit }}</vs-td>
              <vs-td label="Weight (kg)" class="text-right">{{
                tr.weight | numFormat
              }}</vs-td>
              <vs-td label="Volume (m<sup>3</sup>)" class="text-right">{{
                tr.height | numFormat
              }}</vs-td>
              <vs-td label="Qty Origin" class="text-right">{{
                tr.qty_origin | numFormat
              }}</vs-td>
              <vs-td label="Transported" class="text-right">{{
                tr.transported | numFormat
              }}</vs-td>
              <vs-td label="Transporting" class="text-right">
                {{ tr.qty }}
              </vs-td>
              <vs-th>
                <vs-button
                  size="small"
                  color="danger"
                  type="line"
                  @click="deleteTable(index)"
                  icon-pack="feather"
                  icon="icon-trash"
                ></vs-button>
              </vs-th>
            </vs-tr>
          </template>
        </vs-table>
        <div class="vx-col w-full flex">
          <vs-button
            @click="store"
            color="primary"
            icon-pack="feather"
            icon="icon-save"
            >Save</vs-button
          >&nbsp;
          <slot name="footer"></slot>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BrowseJobOrderItem from "@browse/JobOrderItem.vue";
import BrowseDeliveryOrder from "@browse/DeliveryOrder.vue";
import BrowseCompany from "@browse/Company.vue";
import BrowseCommodity from "@browse/Commodity.vue";
import BrowseRoute from "@browse/Route.vue";
// import BrowseVehicleType from '@browse/VehicleType.vue'
import BrowseSelectVehicleType from "@browse_select/VehicleType.vue";
import BrowseVehicle from "@browse/Vehicle.vue";
import BrowseDriver from "@browse/Contact.vue";
import BrowseVendor from "@browse/Contact.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BrowseJobOrder from "@browse/JobOrder.vue";
import BrowseJobOrderD from "@browse/JobOrderDetail.vue";

export default {
  components: {
    BrowseDeliveryOrder,
    BrowseCompany,
    BrowseJobOrderItem,
    BrowseDriver,
    BrowseVendor,
    BrowseVehicle,
    BrowseCommodity,
    BrowseRoute,
    // BrowseVehicleType,
    BrowseSelectVehicleType,
    flatPickr,
    BrowseJobOrder,
    BrowseJobOrderD,
  },
  data() {
    return {
      shipment: {
        type: "new",
        shipping_by: "internal",
      },
      is_open: false,
      name: "",
      company_id: "",
      no_reff: "",
      type: "ftl",
      route_id: null,
      vehicle_type_id: null,
      date_manifest: new Date(),
      etd: null,
      eta: null,
      description: "",
      details: [],
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      flatPickrConfigTime: {
        altFormat: "j M Y - H:i",
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i",
      },
      fd_job_order_id: null,
      fd_job_order_detail_id: null,
      fd_qty: 0,
      fd_is_all: false,
      fd_transport: 0,
      fd_qty_origin: 0,
    };
  },
  watch: {
    fd_qty(val) {
      let value = parseFloat(val);
      if (value > this.fd_qty_origin - this.fd_transport) {
        this.fd_qty = this.fd_qty_origin - this.fd_transport;
      } else if (!value) {
        this.fd_qty = 0;
      }
    },
  },
  methods: {
    ...mapActions({
      dispatchStore: "manifests/store",
      dispatchUpdate: "manifests/update",
      dispatchShow: "manifests/show",
      dispatchGetJo: "job_orders/show",
      dispatchGetJoD: "job_orders/get_commodities",
    }),
    selectItem(d) {
      let trans = parseFloat(d.qty) - parseFloat(d.transported);
      let params = {
        job_order_detail_id: d.id,
        commodity: d.commodity,
        qty: trans,
        qty_origin: d.qty,
        transported: d.transported,
        unit: d.unit,
        volume: parseFloat(d.volume) / trans,
        weight: d.trans ? parseFloat(d.weight) / trans : 0,
      };
      this.appendItem(params);
    },
    openItems() {
      this.is_open = true;
      setTimeout(() => {
        this.is_open = false;
      }, 2000);
    },
    changeDimension() {},
    store() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        let payload = {
          id: this.id,
          company_id: this.company_id,
          route_id: this.route_id,
          vehicle_type_id: this.vehicle_type_id,
          date_manifest: this.date_manifest,
          etd: this.etd,
          eta: this.eta,
          description: this.description,
          no_reff: this.no_reff,
          type: this.type,
          shipment: this.shipment,
          details: this.details,
        };
        try {
          if (this.id) {
            let { data } = await this.dispatchUpdate(payload);
            this.$emit("on-success", data);
          } else {
            let { data } = await this.dispatchStore(payload);
            this.$emit("on-success", data);
          }
        } catch (error) {
          if (error.status == 422) {
            for (let x in error.data) {
              this.$vs.notify({
                title: "Oops!",
                text: error.data[x][0],
                color: "danger",
              });
              break;
            }
          } else {
            this.$vs.notify({
              title: "Oops!",
              text: error.data.message,
              color: "danger",
            });
          }
        }
      });
    },
    commodityChange(data) {
      this.fd_transport = parseFloat(data.transported);
      this.fd_qty_origin = parseFloat(data.qty);
    },
    append() {},
    async getDetail() {
      // let { data } = await this.dispatchShow(this.id)
    },
    deleteTable(i) {
      this.details.splice(i, 1);
    },
    appendItem(d) {
      let params = {
        job_order_detail_id: d.job_order_detail_id,
        commodity: d.commodity,
        qty: d.qty,
        qty_origin: d.qty_origin,
        transported: d.transported,
        unit: d.unit,
        volume: d.volume,
        weight: d.qty ? parseFloat(d.weight) / d.qty : 0,
      };
      this.details.push(params);
    },
    async getFromJo() {
      let { data } = await this.dispatchGetJo(this.$route.query.job_order_id);
      let detail = await this.dispatchGetJoD(this.$route.query.job_order_id);
      this.company_id = data.company_id;
      this.route_id = data.route_id;
      this.vehicle_type_id = data.vehicle_type_id;
      let params;
      for (let i = 0; i < detail.data.length; i++) {
        const d = detail.data[i];
        let trans = parseFloat(d.qty) - parseFloat(d.transported);
        params = {
          job_order_detail_id: d.id,
          commodity: d.commodity,
          qty: trans,
          qty_origin: d.qty,
          transported: d.transported,
          unit: d.unit,
          volume: parseFloat(d.volume) / trans,
          weight: d.trans ? parseFloat(d.weight) / trans : 0,
        };
        this.appendItem(params);
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
    if (this.$route.query.job_order_id) this.getFromJo();
    if (this.$route.query.delivery_order_id) {
      this.shipment.type = "existing";
      this.shipment.delivery_order_id = this.$route.query.delivery_order_id;
    }
  },
};
</script>

<style>
</style>